import React from "react";
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout";
import festplanLogo from "../../assets/festplan_logo.svg";
import downloadAppStoreImage from "../../assets/download_appstore.svg";
import downloadGooglePlayImage from "../../assets/download_google_play.png";
import { Box, Grid } from "theme-ui";
import FestPlanPartners from "../../components/FestPlanPartners";
import { Helmet } from "react-helmet";

const downloadLogoHeight = 70;
const downloadButtons = (
  <p>
    <a href="https://apps.apple.com/de/app/festplan/id1621841354" target="_blank">
      <img
        src={downloadAppStoreImage}
        alt="FestPlan für iOS im AppStore"
        height={downloadLogoHeight}
        style={{ padding: downloadLogoHeight / 6 }}
      />
    </a>

    <a href="https://play.google.com/store/apps/details?id=dev.ptrs.festiplan" target="_blank">
      <img src={downloadGooglePlayImage} alt="FestPlan für Android in Google Play" height={downloadLogoHeight} />
    </a>
  </p>
);

const FestPlan = () => {
  const downloadLogoHeight = 70;

  return (
    <Layout>
      <Helmet>
        <title>FestPlan - Plane deine Festival-Saison</title>
        <meta property="og:title" content="FestPlan - Plane deine Festival-Saison" />
        <meta property="twitter:title" content="FestPlan - Plane deine Festival-Saison" />
        <meta
          name="description"
          content="FestPlan ist eine App um deine Festival-Saison zu planen. Entdecke Festivals die zu deinem Musikgeschmack passen und erstelle deinen persönlichen Timetable je Festival."
        />
        <meta
          name="og:description"
          content="FestPlan ist eine App um deine Festival-Saison zu planen. Entdecke Festivals die zu deinem Musikgeschmack passen und erstelle deinen persönlichen Timetable je Festival."
        />
        <meta
          name="twitter:description"
          content="FestPlan ist eine App um deine Festival-Saison zu planen. Entdecke Festivals die zu deinem Musikgeschmack passen und erstelle deinen persönlichen Timetable je Festival."
        />
        <meta name="image" content="https://www.ptrs.dev/banner_festplan.png" />
        <meta property="og:image" content="https://www.ptrs.dev/banner_festplan.png" />
        <meta property="twitter:image" content="https://www.ptrs.dev/banner_festplan.png" />
        <meta property="twitter:image:alt" content="FestPlan - Plane deine Festival-Saison" />
        <meta property="og:image:alt" content="FestPlan - Plane deine Festival-Saison" />
        <meta property="og:url" content="https://festplan.ptrs.dev" />
        <meta property="twitter:url" content="https://festplan.ptrs.dev" />
      </Helmet>
      <Grid gap={4} columns={[1, "1fr 3fr"]}>
        <Box style={{ maxWidth: 200 }}>
          <img src={festplanLogo} />
        </Box>
        <Box>
          <h1>
            Say Hello to <i>FestPlan</i>
          </h1>
          <h3>Plane deinen Festival-Saison für dich, oder gemeinsam mit deiner Crew!</h3>
          {downloadButtons}
          <p>FestPlan ist kostenlos verfügbar für iOS und Android.</p>
        </Box>
      </Grid>

      <h2>Features</h2>
      <ul>
        <li>Entdecke Festivals die zu deinem Musikgeschmack passen</li>
        <li>Erstelle deinen persönlichen Timetable je Festival</li>
        <li>Folge deinen Freunden um zu sehen auf welche Festivals sie gehen...</li>
        <li>...und bald: plane gemeinsam mit deinen Freunden deinen Timetable (nur mit FestPlan Plus)</li>
      </ul>

      <FestPlanPartners />

      <h2>Download</h2>
      {downloadButtons}

      <h3>Rechtliche Hinweise</h3>
      <p>
        <sub>App Store und das Apple Logo sind Marken von Apple Inc.</sub>
        <br />
        <sub>Google Play und das Google Play-Logo sind Marken von Google LLC.</sub>
      </p>
      <h2>Weiterführende Links</h2>
      <p>
        <a href="/festplan/support">FestPlan Kontakt/Support</a> -{" "}
        <a href="/festplan/support">FestPlan Kontakt/Support</a> - <a href="/festplan/eula">FestPlan EULA (Englisch)</a>
      </p>
    </Layout>
  );
};

export default FestPlan;
