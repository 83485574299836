import React from "react";
import { Flex, Grid } from "theme-ui";
import aquamariaLogo from "../assets/festplan_partner/aquamaria_logo.jpg";
import aukrugLogo from "../assets/festplan_partner/aukrug_logo.jpg";
import rfarLogo from "../assets/festplan_partner/rfar_logo.png";

const Partner = ({ name, logo, url }: { name: string; logo: typeof rfarLogo; url: string }) => {
  return (
    <a href={url}>
      <Flex sx={{ flexDirection: "column" }}>
        <img src={logo} alt={`Logo von ${name}`} style={{ maxWidth: "100%", height: 100, objectFit: "contain" }} />
        <p style={{ textAlign: "center" }}>{name}</p>
      </Flex>
    </a>
  );
};

const FestPlanPartners = () => {
  return (
    <div>
      <h2>Festival Partner</h2>
      <Grid gap={2} columns={[2, 4]}>
        <Partner name="Aquamaria Festival" logo={aquamariaLogo} url="https://aquamaria-festival.myshopify.com/" />
        <Partner name="Aukrug Open Air" logo={aukrugLogo} url="http://www.aukrug-open-air.de/" />
        <Partner name="Rock for Animal Rights" logo={rfarLogo} url="https://www.rockforanimalrights.de/" />
      </Grid>
    </div>
  );
};

export default FestPlanPartners;
